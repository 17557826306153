
.container {
  & * {
    box-sizing: border-box;
  }
  height: 100%;
  position: relative;
  cursor: pointer;
  .controls-btns {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .play-pause-btn {
        height: 100%;
        width: 50px;
        .play-item {
            cursor: pointer;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
                font-size: 26px;
                color: #F5F5F5;
            }
        }
    }
    .process-line {
        flex: 1;
        padding: 0 10px 0 5px;
    }
  }
  .play-box {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: fit-content;
    overflow: hidden;
    border-radius: 5px;
    video {
      width: calc(100% / 0.3);
      height: auto;
      transform: translate(-35%, -10%);
    }
  }
}

